export default {
  title: 'Mes communications',
  subtitle: 'Nos newsletters et alertes',
  text: 'Vitaminez votre semaine en choisissant ce que vous préférez recevoir.',
  email: {
    title: 'Email',
    description:
      'Je souhaite recevoir des offres, des idées de recettes et les nouveaux arrivages en avant-première par email',
  },
  sms: {
    title: 'SMS',
    description:
      'Je souhaite recevoir des offres, des idées de recettes et les nouveaux arrivages en avant-première par SMS',
  },
};
